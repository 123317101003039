import React from "react";

import { Row, Col, Form, Space, Input } from "antd";
import Nav from "../../Components/Nav";
import { Paragraph } from "./styled.components";
import {
	Container,
	StyledButton,
	Heading2,
	Heading,
	StyledLabel,
} from "../../Components/styled.components";
import CustomInput from "../../Components/CustomInput";
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from "antd";
import CustomTable from "../../Components/CustomTable";
import { ReactComponent as DeleteIcon } from "../../Assets/svg/delete.svg";
import { ReactComponent as EditIcon } from "../../Assets/svg/edit.svg";

import NavigationSelect from "../../Components/NavigationSelect";
import CustomModal from "../../Components/CustomModal";
import CustomSelect from "../../Components/CustomSelect";
import httpClient from "../../axios";
import { openNotification } from "../../helper";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { StyledSelect } from "../../Components/styled.components";

const AdminUser = () => {
	const [form] = Form.useForm();
	const [editForm] = Form.useForm();
	const http = httpClient();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { Search } = Input;
	const { Option } = StyledSelect;

	const [deleteUserId, setDeleteUserId] = React.useState("");
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [userList, setUserList] = React.useState([]);
	const [roles, setRoles] = React.useState([]);
	const [editLoading, setEditLoading] = React.useState(false);
	const [isEditModalVisible, setEditModalVisible] = React.useState(false);
	const [editUserId, setEditUserId] = React.useState("");
	const [selectedEditFnGroup, setSelectedEditFnGroup] = React.useState({});
	const [pagination, setPagination] = React.useState({
		itemsPerPage: 10,
		page: 1,
		totalItems: "",
	});
	const props: UploadProps = {
		name: 'users_file',
		action: process.env.REACT_APP_API_URL + 'users/bulk-upload',
		headers: {
		  authorization: 'Bearer ' + localStorage.getItem('token'),
		},
		onChange(info) {
			setUserLoading(true);
		  if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		  }
		  if (info.file.status === 'done') {
			openNotification({
				type: "success",
				message: "Users created"
			});
		  } else if (info.file.status === 'error') {
			openNotification({
				type: "error",
				message: info.file.name
			});
		  }
		},
	  };
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const [userLoading, setUserLoading] = React.useState(false);
	const [rolesLoading, setRolesLoading] = React.useState(false);
	const [createLoading, setCreateLoading] = React.useState(false);
	const [fnGroup, setFnGroup] = React.useState([]);
	const [fnGroupLoading, setFnGroupLoading] = React.useState(false);
	const [selectedFnGroup, setSelectedFnGroup] = React.useState({});
	const [editUser, setEditUser] = React.useState({});
	const [search, setSearch] = React.useState('');

	const onFinish = (values) => {
		setCreateLoading(true);
		const payload = {
			email: values.email,
			password: values.password,
			password_confirmation: values.password_confirmation,
			first_name: values.first_name,
			last_name: values.last_name,
			technician_certificate_code: values.technician_certificate,
			role: location.pathname.split("/")[1],
			fn_group_name: location.pathname.split("/")[1],
		};	
		if (location.pathname === "/admin") {
			payload.fn_group_name = values.fn_group_name;
		} else {
			payload.fn_group_id = selectedFnGroup.key;
		}
		http
			.post("/auth/register", payload)
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					setCreateLoading(false);
					resetForm();
					fetchUsers();
					openNotification({
						type: "success",
						message: 'User created successfully',
					});
				} else {
					throw response;
				}
			})
			.catch((error) => {
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
				});
				console.error(
					"error",
					error.response.data.errors,
					error.response.data.message
				);
				setCreateLoading(false);
			});
	};
	const onFinishFailed = (values) => {
		console.error("error:", values);
	};
	const handleOpenModal = (data) => {
		setDeleteUserId(data.key);
		setIsModalVisible(true);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const closeModal = () => {
		setEditModalVisible(false);
	};
	const fetchUsers = (itemsPerPage, page, search) => {
		setUserLoading(true);
		const params = {
			limit: itemsPerPage || 10,
			page: page || 1,
			search: search,
		};
		if (location.pathname === "/final-user") {
			params.fn_group_id = searchParams.get("fn_group_id");
		}
		http
			.get(`/users?role=${location.pathname.split("/")[1]}`, {
				params: params,
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return {
								key: item.id,
								first_name: item.first_name,
								last_name: item.last_name,
								email: item.email,
								role: item.role || "",
								fn_group: item.fn_group,
								user_pin: item.user_pin,
								student_id: item.student_id,
								technician_certificate_code: item.technician_certificate_code
							};
						});
						setPagination({
							itemsPerPage: response.data.meta.per_page,
							totalItems: response.data.meta.total,
							page: response.data.meta.current_page,
						});
						setUserList({ data, meta: response.data.meta });
						setUserLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setUserLoading(false);
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
					status: (error.response && error.response.status) || "error",
				});
				console.error("error", error);
			});
	};
	const handleDelete = (userId) => {
		setDeleteLoading(true);
		http
			.delete(`/users/${userId}`)
			.then((response) => {
				if (response.status === 204) {
					handleCancel();
					setDeleteLoading(false);
					fetchUsers();
				} else {
					throw response;
				}
			})
			.catch((error) => {
				console.error("error", error);
				setDeleteLoading(false);
			});
	};
	const fetchRoles = () => {
		setRolesLoading(true);
		http
			.get("/roles")
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							console.log({
								key: item.id,
								label: item.value,
								value: item.name,
							});
							return {
								key: item.id,
								label: item.value,
								value: item.name,
							};
						});
						setRoles(data);
						setRolesLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setRolesLoading(false);
				console.error("error", error);
			});
	};
	const fetchFnGroupName = () => {
		setFnGroupLoading(true);
		const params = {
			limit: 10000,
			page: 1
		};
		http
			.get("/fn-groups", { params })
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return {
								key: item.id,
								value: item.name,
							};
						});
						setFnGroup(data);
						setFnGroupLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setFnGroupLoading(false);
				console.error("error", error);
			});
	};
	const handleOpenEditModal = (data) => {

		setEditUser(data);
		setEditUserId(data.key);

		editForm.setFieldsValue({
			first_name: data.first_name,
			last_name: data.last_name,
			fn_group: data.fn_group,
			email: data.email,
			password: data.password,
			password_confirmation: data.password_confirmation,
		});

		setEditModalVisible(true);
	};
	const handleCancelEdit = () => {
		setEditModalVisible(false);
	};
	const bulkInsertUser = (text) => {
		console.log('uppload', text.file.status)
		http
		.post("/users/bulk-upload", text)
		.then((response) => {
			if (response.status === 200) {
				setCreateLoading(false);
				resetForm();
				fetchUsers();
			} else {
				throw response;
			}
		})
		.catch((error) => {
			openNotification({
				type: "error",
				message:
					(error.response && error.response.data.message) || "Error occured",
				errors: (error.response && error.response.data.errors) || [],
			});
			console.error(
				"error",
				error.response.data.errors,
				error.response.data.message
			);
			setCreateLoading(false);
		});
	}
	
	const onFinishEdit = (values) => {
		setEditLoading(true);
		let payload = {};

		console.log(location.pathname.split("/")[1]);
		if (location.pathname.split("/")[1] !== 'final-user') {
			console.log('edit user', selectedEditFnGroup)
			payload = {
				email: values.email || editUser.email,
				password: values.password || "",
				first_name: values.first_name || "",
				last_name: values.last_name || "",
				password_confirmation: values.password_confirmation || "",
				fn_group_id: typeof selectedEditFnGroup == 'object' ? selectedEditFnGroup.key : selectedEditFnGroup,
			};
		} else {
			console.log('in else')
			payload = {
				email: (values.email ? values.email : editUser.email),
				password: (values.password ? values.password : editUser.password),
				password_confirmation: (values.password_confirmation ? values.password_confirmation : editUser.password_confirmation),
				first_name: values.first_name,
				last_name: values.last_name,
				emergency_contact: "2626262",
				role: location.pathname.split("/")[1],
				fn_group_id: typeof selectedEditFnGroup == 'object' ? selectedEditFnGroup.key : selectedEditFnGroup,
				student_id: values.student_id
			};
			console.log('payload in  else', payload);
		}
		// if (location.pathname === "/admin") {
		// 	payload.fn_group_name = values.fn_group_name;
		// } else {
		//payload.fn_group_id = selectedEditFnGroup.key;
		// }
		// const body = new FormData();

		const body = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != ''));
		console.log('payload before call', body);
		http
			.put(`/users/${editUserId}`, body)
			.then((response) => {
				if (response.status === 200) {
					closeModal()
					fetchUsers();
					setEditLoading(false);

				} else {
					throw response;
				}
			})
			.catch((error) => {

				closeModal()
				console.error("error", error);
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
					status: error.response,
				});

				setEditLoading(false);
			});
	};

	const onChangePagination = (page, pageSize) => {
		setPagination({
			itemsPerPage: pageSize,
			page: page,
		});
		fetchUsers(pageSize, page, search);
	};

	const handleSelectFnGroup = (LabeledValue, option) => {
		setSelectedFnGroup(option);
	};

	const onSearch = (data) => {
		setSearch(data);
		fetchUsers(pagination.itemsPerPage, 1, data);
	};

	const resetForm = () => {
		setSelectedFnGroup({});
		form.resetFields();
	};

	React.useEffect(() => {
		resetForm();
		fetchUsers();
		fetchRoles();
		fetchFnGroupName();
	}, [location]);

	const columnsAdmin = [
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
		},
		{
			title: "Last Name",
			dataIndex: "last_name",
			key: "last_name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Trainee Certificate Number",
			dataIndex: "technician_certificate_code",
			key: "technician_certificate_code",
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space size="middle">
					<DeleteIcon onClick={() => handleOpenModal(text)} />
					<EditIcon onClick={() => handleOpenEditModal(text)} />
				</Space>
			),
		},
	];
	const columnsFinalUser = [
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
		},
		{
			title: "Last Name",
			dataIndex: "last_name",
			key: "last_name",
		},
		{
			title: "Trainee Certificate Number",
			dataIndex: "technician_certificate_code",
			key: "technician_certificate_code",
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space size="middle">
					<DeleteIcon onClick={() => handleOpenModal(text)} />
					<EditIcon onClick={() => handleOpenEditModal(text)} />
				</Space>
			),
		},
	];
	const columnsSuperAdmin = [
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
		},
		{
			title: "Last Name",
			dataIndex: "last_name",
			key: "last_name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space size="middle">
					<DeleteIcon onClick={() => handleOpenModal(text)} />
					<EditIcon onClick={() => handleOpenEditModal(text)} />
				</Space>
			),
		},
	];

	return (
		<>
			<Nav />
			<Container>
				<Row>
					<Col span={24}>
						<Heading2>Users</Heading2>
					</Col>
				</Row>
				<Row gutter={[16, 16]} >
					<Col xs={24} md={12} lg={6}>
						<NavigationSelect />
					</Col>
					<Col xs={24} md={12} lg={10}></Col>
					<Col xs={24} md={12} lg={4}>
							{ <a href="https://cfaa.vrts.ca/backend/CFAA-upload-user-sample.xlsx"> <StyledButton > Download sample</StyledButton></a> }
					</Col>
					<Col xs={24} md={12} lg={4}>
							{ 
							<Upload {...props}>
									<StyledButton  icon={<UploadOutlined />}>Upload User</StyledButton>
							</Upload>
							 }
					</Col>
				</Row>
				<Row style={{ marginTop: "50px" }}>
					<Col xs={24}>
						<Heading>
							{location.pathname === "/super-admin"
								? `Add New Super Admin User`
								: location.pathname === "/admin"
									? `Add New Admin User`
									: `Add New Trainee`}
						</Heading>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={24}>
						<Paragraph>
							{location.pathname === "/super-admin"
								? `
							This user can create, edit and delete the following user types:
							super admin, admin, trainee . They also have access to
							see the stats.`
								: location.pathname === "/admin"
									? `
							This user can access trainee stats, add new trainee members, and pdf
							file report.`
									: `
							This user can login to the course with credentials, and their
							stats will be tracked.`}
						</Paragraph>
					</Col>
				</Row>
				<Row>
					<Col xs={24}>
						<Paragraph>
							*Password must contain 2 capital letters, 1 number and can only
							use 0-9, a-z , A-Z , !@#$%^&*?
						</Paragraph>
					</Col>
				</Row>
				<Form
					name="admin-form"
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					style={{ marginTop: "30px" }}
				>
					<Row gutter={[16, 16]}>
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="First name"
								name="first_name"
								rules={[
									{
										required: true,
										message: "Please input first name!",
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="Last name"
								name="last_name"
								rules={[
									{
										required: true,
										message: "Please input last name!",
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="Email Address"
								name="email"
								rules={[
									{
										type: "email",
										message: "The input is not valid E-mail!",
									},
									{

										message: "Please input email address!",
									},
								]}
							/>
						</Col>
						{location.pathname === "/final-user"?(
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="Trainee Certificate"
								name="technician_certificate"
								rules={[
									{
										required: true,
										message: "The input is not valid Trainee certificate number!",
									},
									{

										message: "Please input Trainee certificate number!",
									},
								]}
							/>
						</Col>) : '' }

						{/* )} */}
						{/* {location.pathname === "/final-user" ? (
							<></>
						) : ( */}
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="Password"
								name="password"
								type="password"
								rules={[
									{
										required: false,
										message: "Please input password!",
									},
								]}
							/>
						</Col>
						{/* )} */}
						{/* {location.pathname === "/final-user" ? (
							<></>
						) : ( */}
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="Confirm Password"
								name="password_confirmation"
								type="password"
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "Please confirm your password!",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error("Passwords do not match!")
											);
										},
									}),
								]}
							/>
						</Col>
						{/* )} */}
						{location.pathname !== "/final-user"?(
						<Col xs={24} md={12} lg={8} xl={4}></Col>) : '' }
						<Col
							xs={24}
							md={12}
							lg={8}
							xl={4}
							style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end'  }}
						>
							<StyledButton
								style={{ width: "100%"}}
								htmlType="submit"
								loading={createLoading}
							>
								Save User
							</StyledButton>
						</Col>
					</Row>
					<Row style={{ marginTop: "50px" }}>
						<Col xs={12}>
							<Heading>
								{location.pathname === "/super-admin"
									? `Super Admin User`
									: location.pathname === "/admin"
										? `Admin User`
										: `Trainee`}
							</Heading>
						</Col>
						<Col
							xs={12}
							style={{ display: "flex", justifyContent: "flex-end" }}
						>
							{/* <Search
								style={{ maxWidth: "350px" }}
								placeholder="Search"
								allowClear
								enterButton="Search"
								size="large"
								onSearch={onSearch}
							/> */}
						</Col>
					</Row>
				</Form>
				<CustomModal
					isModalVisible={isModalVisible}
					title="Confirm Delete User"
					loading={deleteLoading}
					handleOk={() => handleDelete(deleteUserId)}
					handleCancel={handleCancel}
				>
					<div>
						Are you sure you want to delete this user. This action is
						irreversible!
					</div>
				</CustomModal>
				<CustomModal
					isModalVisible={isEditModalVisible}
					title="Edit User"
					loading={editLoading}
					handleCancel={handleCancelEdit}
					destroyOnClose
					footer={null}
				>
					<Form
						name="admin-edit-form"
						form={editForm}
						onFinish={onFinishEdit}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						style={{ marginTop: "30px" }}
					>
						<Row gutter={[16, 16]}>
							<Col xs={24}>
								{/* { console.log('selectedEditFnGroup', selectedEditFnGroup)}
								<StyledLabel>Group Name</StyledLabel>
								<StyledSelect value={selectedEditFnGroup} defaultValue={editUser.fn_group} onSelect={setSelectedEditFnGroup}>
									{fnGroup.map((option, index) => {
										return (
											<Option key={option.key || `option-${index}`} value={option.key}>
												{option.value}
											</Option>
										);
									})}
								</StyledSelect> */}
								{/* <CustomSelect
									options={fnGroup}
									loading={fnGroupLoading}
									name="fn_group_name"
									selectedGroupId={selectedFnGroup.key}
									rules={[
										{
											required: true,
											message: "Please input group name!",
										},
									]}
									value={selectedFnGroup}
									defaultValue={selectedFnGroup.value}
									onSelectValue={handleSelectFnGroup}
								/> */}
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="First name"
									name="first_name"
									rules={[
										{
											required: true,
											message: "Please input first name!",
										},
									]}
								/>
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="Last name"
									name="last_name"
									rules={[
										{
											required: true,
											message: "Please input last name!",
										},
									]}
								/>
							</Col>
							{/* {location.pathname === "/final-user" ? (
								<></>
							) : ( */}
							<Col xs={24}>
								<CustomInput
									formlabel="Email Address"
									type="text"
									name="email"
									rules={[
										{
											type: "email",
											message: "The input is not valid E-mail!",
										},
										{
											required: true,
											message: "Please input email address!",
										},
									]}
									disabled={true}
								/>
							</Col>
							{/* )} */}
							{/* {location.pathname === "/final-user" ? (
								<></>
							) : ( */}
							<Col xs={24}>
								<CustomInput
									formlabel="Password"
									name="password"
									type="password"
									hasFeedback
								// rules={[
								// 	{
								// 		required: true,
								// 		message: "Please input password!",
								// 	},
								// ]}
								/>
							</Col>
							{/* )} */}
							{/* {location.pathname === "/final-user" ? (
								<></>
							) : ( */}
							<Col xs={24}>
								<CustomInput
									formlabel="Confirm Password"
									name="password_confirmation"
									type="password"
									dependencies={["password"]}
									hasFeedback
								// rules={[
								// 	{
								// 		required: true,
								// 		message: "Please confirm your password!",
								// 	},
								// 	({ getFieldValue }) => ({
								// 		validator(_, value) {
								// 			if (!value || getFieldValue("password") === value) {
								// 				return Promise.resolve();
								// 			}
								// 			return Promise.reject(
								// 				new Error("Passwords do not match!")
								// 			);
								// 		},
								// 	}),
								// ]}
								/>
							</Col>
							{/* )} */}
							<Col xs={24} style={{ display: "flex", alignItems: "center" }}>
								<StyledButton
									loading={editLoading}
									style={{ width: "100%" }}
									htmlType="submit"
								>
									Save User
								</StyledButton>
							</Col>
						</Row>
					</Form>
				</CustomModal>
				<Row style={{ marginTop: "50px" }}>
					<Col span={24}>
						<CustomTable
							loading={userLoading}
							dataSource={userList}
							columns={
								location.pathname === "/super-admin"
									? columnsSuperAdmin
									: location.pathname === "/admin"
										? columnsAdmin
										: columnsFinalUser
							}
							pagination={{
								pageSize: pagination.itemsPerPage,
								current_page: pagination.page,
								total: pagination.totalItems,
								showSizeChanger: true,
								onChange: onChangePagination,
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default AdminUser;
