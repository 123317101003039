import styled from "styled-components";
import { Button, Input, Select } from "antd";

export const Container = styled.div`
	display: flex;
	padding: 0px 120px;
	flex-direction: column;
	margin-top: 180px;
	@media (max-width: 576px) {
		//xs
		padding: 0px 20px;
	}
	@media (min-width: 577px) and (max-width: 768px) {
		//sm
		padding: 0px 30px;
	}
	@media (min-width: 769px) and (max-width: 992px) {
		//md
		padding: 0px 60px;
	}
	@media (min-width: 993px) and (max-width: 1200px) {
		//lg
		padding: 0px 90px;
	}
`;

export const Heading = styled.h1`
	font-size: 30px;
	letter-spacing: 0px;
`;

export const Heading2 = styled.h2`
	text-align: left;
	font-size: 30px;
	letter-spacing: 0px;
	color: #black;
`;

export const StyledButton = styled(Button)`
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	background-color: #C7141C;
	color: white;
	height: 48px;
	width: 100%;
	font-size: 16px;
	padding: 6px 12px;
	border: 0px;
	&:hover,
	&:focus {
		background-color: #000000;
		color: white;
		border: 0px;
	}
	@media (min-width: 992px) and (max-width: 1200px) {
		//lg
		display: flex !important;
	}
`;

export const StyledInput = styled(Input)`
	background-color: white;
	height: 48px;
	margin-bottom: 0px;
`;

export const StyledSelect = styled(Select)`
	border-radius: 5px;
	color: #2a3840;
	font-family: Montserrat;
	width: 100%;
	.ant-select-selector {
		height: 48px !important;
		display: flex;
		align-items: center;
	}
	&:hover {
		color: #2a3840;
	}
`;

export const StyledLabel = styled.label`
	margin-bottom: 8px;
`;

export const Paragraph = styled.p`
	color: #000000;
	font-family: Montserrat;
	size: 16px;
`;

export const TableLink = styled.button`
	border: 0px;
	background: transparent;
	text-decoration: underline;
	color: #000000;
	font-size: 12px;
	cursor: pointer;
`;


export const Label = styled.div`
	font-size: 18px;
	font-weight: bold;
`