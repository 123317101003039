import React from "react";
import { Form } from "antd";
import { StyledInput, StyledLabel } from "../styled.components";

const CustomInput = (props) => {
	const {
		formlabel = "",
		placeholder = "",
		setInput,
		type = "",
		value = "",
		enterButton,
		disabled = false
	} = props;
	return (
		<>
			<StyledLabel>{formlabel}</StyledLabel>
			<Form.Item {...props}>
				<StyledInput
					enterButton={enterButton}
					value={value}
					onInput={setInput}
					placeholder={placeholder}
					type={type}
					disabled={disabled}
				/>
			</Form.Item>
		</>
	);
};

export default CustomInput;
