import axios from "axios";

export default () => {
	const client = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
	});
	client.interceptors.request.use(function (config) {
		const token = localStorage.getItem("token");
		config.headers.Authorization = token ? `Bearer ${token}` : "";
		config.headers.ContentType = 'Application/json';
		config.headers.Accept = 'Application/json';
		return config;
	});
	return client;
};
