import { Divider } from 'antd';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  padding: 0px 120px;
  flex-direction: column;
  margin-top: 50px;
`

export const CustomDivider = styled(Divider)`
  max-width: 150px;
  min-width: auto;
  background: #51D8D9;
`

export const LoginSection = styled.div`
  margin-top: 30px;
`

export const Heading = styled.h1`
	color: #2a3840;
	text-align: left;
	font-size: 30px;
	font-family: Montserrat;
	font-weight: Bold;
`;