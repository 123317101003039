import React from "react";

import { Row, Col, Input } from "antd";
import Nav from "../../Components/Nav";
import {
	Container,
	Heading2,
	Label,
} from "../../Components/styled.components";
import CustomStatTable from "../../Components/CustomStatTable";
import httpClient from "../../axios";
import { openNotification } from "../../helper";
import CustomModal from "../../Components/CustomModal";
import { StyledSelect } from "../../Components/styled.components";



const StatsOne = () => {
	const http = httpClient();
	const [pagination, setPagination] = React.useState({
		itemsPerPage: 10,
		page: 1,
		totalItems: "",
	});
	const [options] = React.useState([
		{ Label: "Select Module", value: "Module" },
	]);
	const [optionsFilter] = React.useState([
		{ Label: "Filter by", value: "filter" },
	]);
	const { Option } = StyledSelect;

	const [statList, setStatList] = React.useState([]);
	const [details, setDetails] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const [deleteQuizz, setDeleteQuizz] = React.useState("");
	const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
	const [search, setSearch] = React.useState('');
	const [fnGroupLoading, setFnGroupLoading] = React.useState(false);
	const [fnGroup, setFnGroup] = React.useState([]);
	const [newPercentage, setNewPercentage] = React.useState(false);
	const [isEditableResult, setIsEditableResult] = React.useState(false);
	const [groupFilterDefault, setGroupFilterDefault] = React.useState("");
	const [isModalDefectiveVisible, setIsModalDefectiveVisible] = React.useState(false);
	const [defectiveDevices, setDefectiveDevices] = React.useState([]);
	

	// const[setResetFilters] =React.useState("");
	const { Search } = Input;

	const fetchFiltered = (data) => {
		fetchStats(null, null, null, data)
	}

	const setResetFilters = () => {
		fetchStats()
	}

	const changeResultHandler = (e) => {
		console.log('here in this', e)
		setIsEditableResult(true)
	}

	const handleSave = (id, event) => {
		if(event.which == 13){
			event.preventDefault();
			saveNewPercentage(id, event.currentTarget.textContent)
		}
	}


	const handleSaveResult = (id, event) => {
		if(event.which == 13){
			event.preventDefault();
			saveNewResult(id, event.currentTarget.textContent)
		}
	}

	const saveNewPercentage = (id,value ) => {
		console.log('new per', id, value.substr(0, value.length -1));
		http
			.put("/update-percentage/"+ id, {percentage: value.substr(0, value.length -1)})
			.then((response) => {
				if (response.status === 200) {
					setLoading(true);	
					fetchStats();
					openNotification({
						type: "success",
						message: "Record updated successfully",
					});
					
				}else {
					throw response;
				}
			})
			.catch((error) => {
				setLoading(false);
				openNotification({ 
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
					status: (error.response && error.response.status) || "error",
				});
				console.error("error", error);
			});

	}

	const saveNewResult = (id,value ) => {
		console.log('id', id, 'value', value)
		http
			.put("/update-results/"+ id, {result: value})
			.then((response) => {
				if (response.status == 200) {
					setLoading(true);	
					fetchStats();
					openNotification({
						type: "success",
						message: "Record updated successfully",
					});
				}else {
					throw response;
				}
			})
			.catch((error) => {
				setLoading(false);
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
					status: (error.response && error.response.status) || "error",
				});
				console.error("error", error);
			});

	}

	const formatText = (value) => {
		return (value !== "")? value + '%' : '0%'
	}

	const fetchFnGroupName = () => {
		setFnGroupLoading(true);
		// const params = {
		// 	limit: 10000,
		// 	page: 1
		// };
		// http
		// 	.get("/fn-group", { params })
		// 	.then((response) => {
		// 		if (response.status === 200) {
		// 			if (response.data) {
		// 				const data = response.data.data.map((item) => {
		// 					return {
		// 						key: item.id,
		// 						value: item.name,
		// 					};
		// 				});
		// 				setFnGroup(data);
		// 				setFnGroupLoading(false);
		// 			}
		// 		} else {
		// 			throw response;
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		setFnGroupLoading(false);
		// 		console.error("error", error);
		// 	});
	};

	const fetchStats = (itemsPerPage, page, search, filter) => {
		setLoading(true);
		http
			.get(`users/training-attempts`, {
				params: {
					limit: itemsPerPage || 10,
					page: page || 1,
					search: search,
					group_id: filter
				},
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							console.log('item',item)
							return {
								key: item.id,
								name: item.name,
								attempts: item.attempts,
								technician_certificate_code: item.technician_certificate_code
							};
						});
						setPagination({
							itemsPerPage: response.data.per_page || 10,
							totalItems: response.data.total,
							page: response.data.current_page,
						});
						setStatList({ data, meta: response.data.meta });
						setLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setLoading(false);
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
					status: (error.response && error.response.status) || "error",
				});
				console.error("error", error);
			});
	};


	const onSearch = (data) => {
		setSearch(data);
		fetchStats(pagination.itemsPerPage, 1, data);
	};

	const onChangePagination = (page, pageSize) => {
		setPagination({
			itemsPerPage: pageSize,
			page: page,
		});
		fetchStats(pageSize, page);
	};

	const onSeeDetails = (data) => {
		setIsModalDefectiveVisible(true);
		setDetails(data);
	};

	const handleDefectiveDeviceCancelModal = () => {
		setIsModalDefectiveVisible(false);
	}

	const handleCancelModal = () => {
		setIsModalVisible(false);
	};
	// Handle delete
	const handleOpenDeleteModal = (data) => {
		setDeleteQuizz(data.key);
		setIsDeleteModalVisible(true);
	};
	const handleDelete = (quizz) => {
		setDeleteLoading(true);
		http
			.delete(`/quizzes/${quizz}`)
			.then((response) => {
				if (response.status === 204) {
					handleCancelDelete();
					setDeleteLoading(false);
					fetchStats();
				} else {
					throw response;
				}
			})
			.catch((error) => {
				console.error("error", error);
				setDeleteLoading(false);
			});
	};
	const handleCancelDelete = () => {
		setIsDeleteModalVisible(false);
	};
	const handleDownloadStats = () => {
		console.log('/download stats here')
	}

	React.useEffect(() => {
		fetchStats();
		fetchFnGroupName();
	}, []);

	const columns = [
		{
			title: "Name",
			render: (text) => {
				console.log('herehere', text)
				return text.name || 'n/a'
			}
		},
		{
			title: "Trainee Certificate Number",
			render: (text) => {
				return text.technician_certificate_code || 'n/a'
			}
		},
		{
			title: "Exam Date",
			render: (text) => {
				if( text.attempts.length > 0)
				{
					return text.attempts.map(attempt => {
						return  <div><p>{attempt.exam_date}</p></div>
					});
				}else {
					return <p>-</p>
				}
			}
		},
		{
			title: "Time Elapsed",
			render: (text) => {
				if( text.attempts.length > 0)
				{
					return text.attempts.map(attempt => {
						return <p>{attempt.time_elapsed}</p>
					});
				}else {
					return <p>-</p>
				}
			}
		},
		{
			title: "Percentage",
			render: (text) => {
				if( text.attempts.length > 0)
				{
					return text.attempts.map((attempt) => {
						return (
							<p>
								<div contentEditable="true"  onKeyDown={e => handleSave(attempt.id, e)}> 
									<p>{!attempt.percentage ? '-': attempt.percentage + '%'}</p>
								</div>
							</p>
						  )
					});
				}else{
					return <p>-</p>
				}
			},
		},
		{
			title: "Result",
			render: (text) => {
				if( text.attempts.length > 0)
				{
					return text.attempts.map(attempt => {
						return (
							<p>
								<div> 
									<p>{!attempt.percentage ? '-': (attempt.percentage >= 80 ? 'Pass' : 'Fail')}</p>
								</div>
							</p>
						  )
					});
				} else {
					return (<p onDoubleClick={changeResultHandler}>-</p>)
				}
			}
		},
		{
			title: "Defective devices",
			render: (text) => {
				if( text.attempts.length > 0)
				{
					return text.attempts.map(attempt => {
						return (<p><a href={'javascript:;'} onClick={() => onSeeDetails(attempt.damages)}>view</a></p>)
					});
				}else{
					return (<p onDoubleClick={changeResultHandler}>-</p>)
				}
			}
		},
		{
			title: "Report",
			render: (text) =>{
				if( text.attempts.length > 0)
				{
					return text.attempts.map(attempt => {
						return <p> <a target={"_blank"} href={'https://cfaa.vrts.ca/backend/pdf-viewer/' + attempt.id} rel="noreferrer">{attempt.company_name}</a></p>
					});
				}else {
					return <p>-</p>
				}
			}
		}
	];
	return (
		<>
			<Nav />
			<Container>
				<Row
					gutter={[16, 16]}
					style={{ justifyContent: "space-between", alignItems: "flex-end" }}
				>
					<Col xs={24} md={12} lg={6}>
						<Heading2>Stats</Heading2>
					</Col>
					<Col
						xs={24}
						style={{ display: "flex", justifyContent: "flex-end" }}
					>
						{/* <Search
							style={{ maxWidth: "350px" }}
							placeholder="Search"
							allowClear
							enterButton="Search"
							size="large"
							onSearch={onSearch}
						/> */}
					</Col>
					<Col xs={24} md={6} lg={3}>
					</Col>
				</Row>
				{
					<Row gutter={[16, 8]}>
						<Col
							xs={12}
							sm={12}
							md={4}
							lg={3}
							xl={2}
							style={{ display: "flex", justifyContent: "flex-start" }}
						>
						</Col>
					</Row>
				}
				<Row style={{ marginTop: "50px" }}>
					<Col span={24}>
						<CustomStatTable
							loading={loading}
							dataSource={statList}
							columns={columns}
							pagination={{
								pageSize: pagination.itemsPerPage,
								current_page: pagination.page,
								total: pagination.totalItems,
								showSizeChanger: true,
								onChange: onChangePagination,
							}}
						/>
					</Col>
				</Row>

				<CustomModal
					isModalVisible={isModalDefectiveVisible}
					title="View Defective devices"
					handleCancel={handleDefectiveDeviceCancelModal}
					destroyOnClose
					footer={null}
				>	
					{details &&
						details.map((damages) => (
							<Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
								<Col xs={24}>
									<p> {damages.device_name + ' - ' + damages.device_location + ' - ' + damages.damages}</p>
								</Col>
							</Row>
						)) }
					{(details.length < 1)? 
						(<h3> No data available</h3>)
						: ''

					}
				</CustomModal>
			</Container>
		</>
	);
};

export default StatsOne;
